import Vue from 'vue';

export default {
  SET_LIST_CHARGES(state, payload) {
    state.charges = payload;
  },
  SET_CHARGES_CURRENT_PAGE(state, page) {
    state.filter.page = page;
  },
  SET_CHARGES_PAGES(state, pages) {
    state.filter.pages = pages;
  },
  SET_CHARGE(state, charge) {
    state.charge = charge;
  },
  SET_CHARGES_FILTER(state, filter) {
    const currentFilter = state.filter;
    state.filter = {
      ...currentFilter,
      ...filter,
    };
  },
  SET_CHARGE_ACTIVE_TAB(state, slug) {
    state.activeTab = slug;
  },
  SET_CHARGES_BOARD_STATS(state, stats) {
    state.chargesBoardStats = stats;
  },
  SET_CHARGE_ON_CURRENT_LIST(state, updatedCharge) {
    const chargeIndexOnList = state.charges.map((charge) => charge.id).indexOf(updatedCharge.id);
    Vue.set(state.charges, chargeIndexOnList, updatedCharge);
  },
};
