import Vue from 'vue';
import VueMask from 'v-mask';
import App from './App.vue';

import router from './router';
import store from './store';

import './plugins/plugins';

import './assets/scss/app.scss';
import './globalFilters/filters';
import '../node_modules/jquery/dist/jquery.min';
import '../node_modules/popper.js/dist/popper.min';
import '../node_modules/bootstrap/dist/js/bootstrap';

Vue.use(VueMask);

Vue.config.productionTip = false;

window.VueInstance = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
