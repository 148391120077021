import services from '../services';

const ActionGetLastWeekPaidChargesByPaymentMethods = async ({ commit }) => {
  const response = await services.getLastWeekPaidChargesByPaymentMethods();
  commit('SET_LAST_WEEK_PAID_CHARGES', response.data);
};

const ActionGetChargesPerProduct = async ({ commit }) => {
  const response = await services.getChargesPerProduct();
  commit('SET_CHARGES_PER_PRODUCT', response.data);
};

const ActionGetReceivedPostsNotProcessed = async ({ commit }) => {
  const response = (await services.getPostsNotProcessed()).data;

  const {
    total,
    today,
    per_platform: perPlatform,
    per_platform_today: perPlatformToday,
  } = response;

  commit('SET_NOT_PROCESSED_TOTAL', total);
  commit('SET_NOT_PROCESSED_TODAY', today);
  commit('SET_NOT_PROCESSED_PER_PLATFORM', perPlatform);
  commit('SET_NOT_PROCESSED_PER_PLATFORM_TODAY', perPlatformToday);
};

export default {
  ActionGetLastWeekPaidChargesByPaymentMethods,
  ActionGetChargesPerProduct,
  ActionGetReceivedPostsNotProcessed,
};
