<template>
  <div class="template-container">
    <nav class="template-nav" :class="{ 'template-nav-open': GetterShowMenuIndicator }">
      <div class="template-logo">
        <div class="logo-container">
          <img src="../assets/images/logo_ghrs.png" />
        </div>
        <div class="show_on_mobile_flex">
          <i class="fa fa-arrow-left" @click="ActionToggleShowMenuIndicator"></i>
        </div>
      </div>
      <hr />
      <div class="template-menu">
        <ul>
          <li v-for="(item, i) in menuItems" :key="i">
            <template-menu-item :icon="item.icon" :text="item.text" :path="item.path" />
          </li>
        </ul>
      </div>
    </nav>
    <main class="template-main">
      <header class="template-header">
        <div class="toggle-button">
          <button class="btn btn-primary" @click="ActionToggleShowMenuIndicator">
            <i class="fa fa-bars"></i>
          </button>
        </div>
        <div class="template-info">
          <span class="template-info-user"> <i class="fa fa-user"></i>{{user.name}}</span>
          <i class="fa fa-sign-out-alt" @click="signOut" title="Sair"></i>
        </div>
      </header>
      <div class="template-content" :class="{ 'template-nav-open': GetterShowMenuIndicator }">
        <slot></slot>
      </div>
    </main>
  </div>
</template>
<script type="text/javascript">
import { mapActions, mapGetters, mapState } from 'vuex';
import navigation from '@/navigation/navigation';
import TemplateMenuItem from './templateMenuItem.vue';

export default {
  components: {
    TemplateMenuItem,
  },
  computed: {
    ...mapGetters('template', ['GetterShowMenuIndicator']),
    ...mapState('auth', [
      'user',
    ]),
    menuItems() {
      return navigation.filter(
        (navigationItem) => {
          const navigationAuthorizedForThisUser = (typeof navigationItem.acl === 'undefined')
          || navigationItem.acl.includes(this.user.level);
          return navigationAuthorizedForThisUser;
        },
      );
    },
  },
  mounted() {
    this.ActionSetInitialShowMenuIndicator();
  },
  methods: {
    ...mapActions('auth', ['ActionSignOut']),
    ...mapActions('template', [
      'ActionToggleShowMenuIndicator',
      'ActionSetInitialShowMenuIndicator',
    ]),
    signOut() {
      this.ActionSignOut();
      this.$router.replace({ name: 'login' });
    },
  },

};
</script>
<style lang="scss">
.logo-container{
  width: 100%;
  padding-bottom:20px;
  img {
    width:70%;
    height: auto;
  }
}
.template-container {
  display: flex;
  flex-direction: row;
  background-color: navy;
}
.template-nav {
  width: 0px;
  overflow: hidden;
  background: url("../assets/images/nav-background.jpg") rgba(0, 0, 0, 0.6);
  background-size: cover;
  background-blend-mode: multiply;
  height: 100vh;
  padding: 0px;
  color: white;
  transition: 0.4s;
  top: 0;
  left: 0;
  z-index: 1;
}
@media (max-width: 600px) {
  .template-nav {
    position: fixed;
  }
}
.template-nav.template-nav-open {
  width: 260px;
  padding: 20px;
  flex-shrink: 0;
}

.template-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.template-logo {
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid white;
}
.template-main {
  background-color: #eee;
  flex-grow: 1;
  flex-basis: 1;

  height: 100vh;
}
.template-header {
  padding: 10px;
  height: 60px;
  background-color: white;
  align-content: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}
.template-info {
  display: flex;
  justify-content: center;
  align-items: center;
}
.template-info-user {
  margin-right: 20px;
  background-color: var(--standard-color);
  text-transform: uppercase;
  color: white;
  padding: 9px 20px;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 700;
}
.template-info-user i {
  margin-right: 10px;
  font-size: 1rem;
}
.template-content {
  height: calc(100vh - 60px);
  overflow-x: hidden;
  padding: 20px;
  box-sizing: border-box;
  width: 100vw;
  transition: 0.4s;
}
.template-content.template-nav-open {
  width: calc(100vw - 260px);
}
@media (max-width: 600px) {
  .template-content {
    width: 100vw;
  }
  .template-content.template-nav-open {
    width: 100vw;
  }
}

.fa-sign-out-alt {
  font-size: 1.5rem;
  margin-right: 20px;
  color: var(--standard-color);
  cursor: pointer;
}
</style>
