export default {
  platforms: [
    { key: 'appmax', value: 'AppMax' },
    { key: 'eduzz', value: 'Eduzz' },
    { key: 'perfectpay', value: 'PerfectPay' },
    { key: 'monetizze', value: 'Monetizze' },
    { key: 'braip', value: 'Braip' },
    { key: 'hotmart', value: 'Hotmart' },
    { key: 'upnid', value: 'Upnid' },
  ],
};
