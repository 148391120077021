export default {
  SET_USER_LIST(state, payload) {
    state.users = payload;
  },
  SET_USER_LIST_CURRENT_PAGE(state, payload) {
    state.usersFilter.page = payload;
  },
  SET_USER_LIST_PAGES(state, payload) {
    state.usersFilter.pages = payload;
  },
  SET_USER_LIST_FILTER(state, payload) {
    const filterKeys = Object.keys(payload);
    filterKeys.forEach((key) => { state.usersFilter[key] = payload[key]; });
  },
};
