import services from '../services';

const ActionListReceivedPosts = async ({ state, commit }) => {
  try {
    const response = await services.listReceivedPosts(state.filter);
    commit('SET_LIST_receivedPosts', response.data.data);
    commit('SET_receivedPosts_CURRENT_PAGE', response.data.current_page);
    commit('SET_receivedPosts_PAGES', response.data.last_page);
    return Promise.resolve();
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

const ActionListReceivedPostsPaginate = async ({ dispatch, commit }, { page }) => {
  commit('SET_receivedPosts_CURRENT_PAGE', page);
  await dispatch('ActionListReceivedPosts');
};

const ActionReprocessReceivedPost = async (context, { id }) => {
  try {
    await services.reprocessReceivedPost(id);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

const ActionDeleteReceivedPost = async (context, { id }) => {
  try {
    await services.deleteReceivedPost(id);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

const ActionListReceivedPostsPlatforms = async ({ commit }) => {
  try {
    const platforms = (await services.retrievePlatforms()).data;
    commit('SET_LIST_PLATFORMS', platforms);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

const ActionReceivedPostsFilterPlatform = async ({ commit, dispatch }, { platform }) => {
  commit('SET_PLATFORM_FILTER', platform);
  commit('SET_receivedPosts_CURRENT_PAGE', 1);
  await dispatch('ActionListReceivedPosts');
};

export default {
  ActionListReceivedPosts,
  ActionListReceivedPostsPaginate,
  ActionReprocessReceivedPost,
  ActionDeleteReceivedPost,
  ActionListReceivedPostsPlatforms,
  ActionReceivedPostsFilterPlatform,
};
