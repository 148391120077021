import services from '../services';

const createEmptyMessageObject = () => ({
  title: '',
  message: '',
  standard_for_product: '',
  standard_for_situation: '',
});

const createEmptyMessageErrorObject = () => ({
  title: '',
  message: '',
  standard_for_product: '',
  standard_for_situation: '',
});

const ActionCreateEmptyMessageObject = async ({ commit, dispatch }) => {
  commit('SET_MESSAGE', createEmptyMessageObject());
  await dispatch('ActionResetMessageErrors');
};

const ActionResetMessageErrors = ({ commit }) => {
  commit('SET_MESSAGE_ERROR', createEmptyMessageErrorObject());
};

const ActionSetMessageErrors = ({ commit }, error) => {
  const { errors } = error.response.data;
  const errorsToSet = createEmptyMessageErrorObject();
  Object.entries(errors).forEach(([key, value]) => {
    const [message] = value;
    errorsToSet[key] = message;
  });
  commit('SET_MESSAGE_ERROR', errorsToSet);
};

const ActionListMessages = async ({ commit, state }) => {
  try {
    const filter = state.messagesFilter;
    const messages = (await services.messages.list(filter)).data;
    commit('SET_MESSAGES_LIST', messages.data);
    commit('SET_MESSAGES_LIST_CURRENT_PAGE', messages.current_page);
    commit('SET_MESSAGES_LIST_PAGES', messages.last_page);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

const ActionListMessagesPaginate = async ({ commit, dispatch }, { page }) => {
  commit('SET_MESSAGES_LIST_CURRENT_PAGE', page);
  await dispatch('ActionListMessages');
};

const ActionListMessagesApplyFilter = async ({ commit, dispatch }, { filter }) => {
  commit('SET_MESSAGES_LIST_FILTER', filter);
  await dispatch('ActionListMessages');
};

const ActionCreateMessage = async ({ dispatch }, { data }) => {
  await dispatch('ActionResetMessageErrors');
  try {
    const { data: message } = await services.messages.create(data);
    await dispatch('ActionCreateEmptyMessageObject');
    await dispatch('ActionListMessages');
    return Promise.resolve(message);
  } catch (error) {
    await dispatch('ActionSetMessageErrors', error);
    return Promise.reject(error);
  }
};

const ActionReadMessage = async ({ commit }, { id }) => {
  try {
    const { data: message } = (await services.messages.read(id));
    commit('SET_MESSAGE', message);
    return Promise.resolve(message);
  } catch (error) {
    return Promise.reject(error);
  }
};

const ActionUpdateMessage = async ({ dispatch }, { id, data }) => {
  await dispatch('ActionResetMessageErrors');
  try {
    const { data: message } = await services.messages.update(id, data);
    await dispatch('ActionCreateEmptyMessageObject');
    await dispatch('ActionListMessages');
    return Promise.resolve(message);
  } catch (error) {
    await dispatch('ActionSetMessageErrors', error);
    return Promise.reject(error);
  }
};

const ActionDeleteMessage = async (context, { id }) => {
  try {
    await services.messages.destroy(id);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  ActionListMessages,
  ActionListMessagesPaginate,
  ActionListMessagesApplyFilter,
  ActionCreateEmptyMessageObject,
  ActionResetMessageErrors,
  ActionSetMessageErrors,
  ActionCreateMessage,
  ActionReadMessage,
  ActionUpdateMessage,
  ActionDeleteMessage,
};
