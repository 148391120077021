import { http } from '@/http';

const services = {
  lastWeekPaidChargesByPaymentMethods: {
    url: '/apidashboard/charges_dashboard_payments_by_method',
  },
  chargesPerProduct: {
    url: '/apidashboard/charges_dashboard_product_conversion',
  },
  receivedPostsNotProcessed: {
    url: '/apidashboard/posts_not_processed',
  },
};

const getLastWeekPaidChargesByPaymentMethods = async () => http({
  method: 'get',
  url: services.lastWeekPaidChargesByPaymentMethods.url,
});

const getChargesPerProduct = async () => http({
  method: 'get',
  url: services.chargesPerProduct.url,
});

const getPostsNotProcessed = async () => http({
  method: 'get',
  url: services.receivedPostsNotProcessed.url,
});

const listUsers = () => http.get('/allusers');

export default {
  listUsers,
  getLastWeekPaidChargesByPaymentMethods,
  getChargesPerProduct,
  getPostsNotProcessed,
};
