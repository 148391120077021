import { http } from '@/http';

const services = {
  listProducts: {
    method: 'get',
    url: '/apiproducts?per_page=1000',
  },
};

const listProducts = () => http({
  method: services.listProducts.method,
  url: services.listProducts.url,
});

export default {
  listProducts,
};
