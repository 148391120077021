import Vue from 'vue';
import dateHandler from '@/functions/dateHandler/dateHandler';

const GetterLastWeekPaymentsByMethod = (state) => {
  const { lastWeekPaidChargesPerDate } = state;
  const formattedArray = Object.keys(lastWeekPaidChargesPerDate).map((date) => {
    const chargesFromDate = lastWeekPaidChargesPerDate[date];
    let billet = 0;
    let creditCard = 0;
    let pix = 0;
    let others = 0;
    chargesFromDate.forEach((paymentMethodSum) => {
      switch (paymentMethodSum.payment_method) {
        case 'boleto':
          billet = paymentMethodSum.count;
          break;
        case 'cartao_de_credito':
          creditCard = paymentMethodSum.count;
          break;
        case 'pix':
          pix = paymentMethodSum.count;
          break;
        default:
          others = paymentMethodSum.count;
          break;
      }
    });
    if (others) console.log(others);
    return [dateHandler.format(date, 'DD/MM/Y'), billet, creditCard, pix];
  });
  return [
    ['Data', 'Boleto', 'Cartão', 'Pix'],
    ...formattedArray,
  ];
};

const colorsByStatus = {
  order_overdue: '#0879fa',
  order_pending: '#FA7220',
  credit_card_refused: '#FAD720',
  order_approved: '#14F8FA',
  order_refunded: '#FA20BD',
  other1: '#37FA14',
  other2: '#FAAC20',
};

const GetterChargesPerProduct = (state) => {
  const { chargesPerProduct } = state;

  return Object.entries(chargesPerProduct).map(([productName, items]) => {
    const chargesCounter = items.reduce((acct, item) => acct + item.count, 0);
    const innerItems = items.map((item) => [Vue.filter('chargeStatus')(item.status), item.count, colorsByStatus[item.status] ?? 'silver', item.count]);
    const paidCharges = items.find((item) => item.status === 'order_approved')?.count || 0;
    const conversionPercentage = Math.ceil((paidCharges / chargesCounter) * 100);
    return {
      productName,
      chargesSummary: [['Status', 'Cobranças', { role: 'style' }, { role: 'annotation' }], ...innerItems],
      conversionPercentage,
      chargesCounter,
    };
  });
};

const colorsByPlatform = [
  '#0879fa',
  '#FA7220',
  '#FAD720',
  '#14F8FA',
  '#FA20BD',
  '#37FA14',
  '#FAAC20',
];

const GetterNotProcessedPerPlatform = (state) => {
  const platforms = state.notProcessed.receivedPostsNotProcessedPerPlatform.map(
    (total) => total.platform,
  );

  return [
    ['Operadora', 'Postbacks', { role: 'style' }, { role: 'annotation' }],
    ...state.notProcessed.receivedPostsNotProcessedPerPlatform.map(
      (item) => [
        item.platform,
        item.count,
        colorsByPlatform[platforms.indexOf(item.platform)] ?? 'silver',
        item.count,
      ],
    ),
  ];
};

const GetterNotProcessedPerPlatformToday = (state) => {
  const platforms = state.notProcessed.receivedPostsNotProcessedPerPlatform.map(
    (total) => total.platform,
  );

  const todayPerPlatorm = state.notProcessed.receivedPostsNotProcessedPerPlatformToday.reduce(
    (acct, item) => ({
      ...acct,
      [item.platform]: item.count,
    }),
    {},
  );

  const totalWithAllPlatforms = platforms.map((platform) => [
    platform,
    todayPerPlatorm[platform] ?? 0,
    colorsByPlatform[platforms.indexOf(platform)] ?? 'silver',
    todayPerPlatorm[platform] ?? 0,
  ]);

  return [
    ['Operadora', 'Postbacks', { role: 'style' }, { role: 'annotation' }],
    ...totalWithAllPlatforms,
  ];
};

export default {
  GetterLastWeekPaymentsByMethod,
  GetterChargesPerProduct,
  GetterNotProcessedPerPlatform,
  GetterNotProcessedPerPlatformToday,
};
