export default [
  {
    path: '/alteracaodesenha',
    name: 'password_change',
    component: () => import(/* webpackChunk: 'alteracaodesenha' */ '@/modules/password_change/PasswordChange.vue'),
    meta: {
      protected: true,
      acl: ['manager', 'operator'],
      title: 'Alteração de senha',
      layout: 'default_template',
    },
  },
];
