import auth from '@/modules/auth/routes';
import dashboard from '@/pages/dashboard/routes';
import managers from '@/pages/managers/routes';
import operators from '@/pages/operators/routes';
import passwordChange from '@/modules/password_change/routes';
import billingMessages from '@/pages/billingmessages/routes';
import receivedPosts from '@/pages/receivedposts/routes';
import charges from '@/pages/charges/routes';
import history from '@/pages/history/routes';
import products from '@/pages/products/routes';
import standardroutes from './standardRoute';

export default [
  ...auth,
  ...dashboard,
  ...managers,
  ...operators,
  ...passwordChange,
  ...billingMessages,
  ...receivedPosts,
  ...charges,
  ...history,
  ...products,
  ...standardroutes,
];
