<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script type="text/javascript">
import DefaultTemplate from '@/theme/template/template.vue';
import FullScreen from '@/theme/template/FullScreen.vue';

export default {
  components: {
    DefaultTemplate,
    FullScreen,
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'fullscreen') return 'FullScreen';
      return 'DefaultTemplate';
    },
  },
};
</script>
<style src="@/theme/assets/css/style.css"></style>
