import { http } from '@/http';

const services = {
  changeCurrentUserPassword: {
    method: 'post',
    url: '/apiusers/change_current_user_password',
  },
};

const changeCurrentUserPassword = (payload) => http({
  method: services.changeCurrentUserPassword.method,
  url: services.changeCurrentUserPassword.url,
  data: payload,
});

export default {
  changeCurrentUserPassword,
};
