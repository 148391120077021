<template>
  <router-link
    class="template-menu-item"
    :to="this.path"
    :class="{ 'selected-route': this.path == this.$route.path }"
  >
    <i class="fa" :class="this.icon"></i>
    <span>{{ this.text }}</span>
  </router-link>
</template>
<script type="text/javascript">
export default {
  props: {
    icon: String,
    text: String,
    path: String,
  },
};
</script>
<style type="text/css" scoped>
.template-menu-item {
  padding: 10px 15px;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 5px;
  white-space: nowrap;
  color: white;
  display: block;
  text-decoration: none;
}
.template-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.template-menu-item i {
  margin-right: 20px;
}
.selected-route {
  background-color: var(--standard-color);
}
</style>
