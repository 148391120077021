const standardDataActions = [
  {
    icon: 'fa-edit',
    text: 'Editar administrador ',
    event: 'edit_record',
    color: 'orange',
  },
  {
    icon: 'fa-trash',
    text: 'Apagar administrador',
    event: 'delete_record',
    color: 'red',
  },
];
const actionBlock = {
  icon: 'fa-ban',
  text: 'Bloquear acesso ',
  event: 'block_record',
  color: 'grey',
};
const actionUnblock = {
  icon: 'fa-check',
  text: 'Desbloquear acesso ',
  event: 'unblock_record',
  color: 'green',
};

const getUsersWithActions = (state) => state.users.map((user) => {
  const actions = [
    ...standardDataActions,
    user.status === 'active'
      ? actionBlock
      : actionUnblock,
  ];
  return {
    ...user,
    actions,
  };
});

export default {
  getUsersWithActions,
};
