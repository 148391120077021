export default {
  lastWeekPaidChargesPerDate: [],
  chargesPerProduct: [],
  notProcessed: {
    receivedPostsNotProcessed: 0,
    receivedPostsNotProcessedToday: 0,
    receivedPostsNotProcessedPerPlatform: [],
    receivedPostsNotProcessedPerPlatformToday: [],
  },
};
