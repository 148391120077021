export default [
  {
    path: '/administradores',
    name: 'managers_list',
    component: () => import(/* webpackChunk: 'managers_list' */ '@/pages/managers/Users.vue'),
    meta: {
      title: 'Administradores',
      protected: true,
      acl: ['manager'],
      layout: 'default_template',
    },
  },
];
