import services from '../services';

const ActionListCharges = async ({ state, commit }) => {
  try {
    const response = await services.listCharges(state.filter);
    commit('SET_LIST_CHARGES', response.data.data);
    commit('SET_CHARGES_CURRENT_PAGE', response.data.current_page);
    commit('SET_CHARGES_PAGES', response.data.last_page);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

const ActionListChargesPaginate = async ({ dispatch, commit }, { page }) => {
  commit('SET_CHARGES_CURRENT_PAGE', page);
  await dispatch('ActionListCharges');
};

const ActionGetCharge = async ({ commit }, { id }) => {
  try {
    const charge = (await services.getCharge(id)).data;
    commit('SET_CHARGE', charge);
    return Promise.resolve(charge);
  } catch (error) {
    return Promise.reject(error);
  }
};

const ActionSetActiveTab = ({ commit }, { slug }) => {
  commit('SET_CHARGE_ACTIVE_TAB', slug);
  commit('SET_CHARGES_FILTER', { active_tab: slug });
};

const ActionGetChargesBoardStats = async ({ commit, state }) => {
  try {
    const filterForResearch = { ...state.filter };
    delete filterForResearch.active_tab;
    const response = await services.getChargesBoardStats(filterForResearch);
    commit('SET_CHARGES_BOARD_STATS', response.data);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

const actionRegisterContact = async ({ dispatch, commit }, { chargeId, userId }) => {
  try {
    const dataToRegister = {
      charge_id: chargeId,
      user_id: userId,
    };
    await services.registerContact(dataToRegister);
    const updatedCharge = await dispatch('ActionGetCharge', { id: chargeId });
    commit('SET_CHARGE_ON_CURRENT_LIST', updatedCharge);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

const actionCloseCharge = async (context, { chargeId }) => {
  const response = await services.closeCharge(chargeId);
  return Promise.resolve(response);
};

export default {
  ActionListCharges,
  ActionListChargesPaginate,
  ActionGetCharge,
  ActionSetActiveTab,
  ActionGetChargesBoardStats,
  actionRegisterContact,
  actionCloseCharge,
};
