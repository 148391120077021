import services from '../services';

const ActionListOrdersOrigins = async ({ commit }) => {
  try {
    const { data: ordersOrigins } = (await services.listOrdersOrigins());
    commit('SET_ORDERS_ORIGINS', ordersOrigins);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject();
  }
};

export default {
  ActionListOrdersOrigins,
};
