export default {
  messages: [],
  messagesFilter: {
    page: 1,
    per_page: 10,
    pages: 1,
    standard_for_product: '',
  },
  message: {
    title: '',
    message: '',
    standard_for_product: '',
    standard_for_situation: '',
  },
  messageErrors: {
    title: '',
    message: '',
    standard_for_product: '',
    standard_for_situation: '',
  },
};
