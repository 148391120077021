export default [
  {
    path: '/nao_processados',
    name: 'receivedPosts',
    component: () => import(/* webpackChunk: 'receivedPosts' */ '@/pages/receivedposts/pages/ReceivedPosts'),
    meta: {
      title: 'Entradas não processadas',
      protected: true,
      acl: ['manager'],
      layout: 'default_template',
    },
  },
];
