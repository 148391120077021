import { http } from '@/http';

const services = {
  login: { method: 'post', url: '/apilogin/login' },
  loadSession: { method: 'get', url: '/apilogin/get_current_user' },
};

const attempt = (email, password) => http({
  method: services.login.method,
  url: services.login.url,
  data: {
    email,
    password,
  },
});

const loadSession = () => http({
  method: services.loadSession.method,
  url: services.loadSession.url,
});

export default {
  attempt,
  loadSession,
};
