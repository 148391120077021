import services from '../services';

const ActionListOperators = async ({ commit }) => {
  try {
    const { data: operators } = (await services.listOperators()).data;
    commit('SET_OPERATORS_LIST', operators);
    return Promise.resolve(operators);
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  ActionListOperators,
};
