import services from '../services';

const ActionListProducts = async ({ commit }) => {
  try {
    const products = (await services.listProducts()).data;
    commit('SET_PRODUCTS_LIST', products);
    return Promise.resolve(products);
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  ActionListProducts,
};
