import services from '../services';

const ActionListProducts = async ({ commit, state }) => {
  const { filter } = state;
  const res = await services.listProducts(filter);
  commit('SET_PRODUCTS_LIST', res.data);
  return Promise.resolve();
};

const ActionToggleStatus = (context, { productId }) => services.toggleStatus(productId);

export default {
  ActionListProducts,
  ActionToggleStatus,
};
