import functions from '@/functions';

const services = {
  billingMessages: {
    url: '/apibillingmessages',
  },
};

export default {
  messages: functions.createCrudServices(services.billingMessages.url),
};
