import routes from './routes';

const unprotectedRoutes = routes.filter(
  (route) => !route.meta || !route.meta.protected,
)
  .map((route) => route.name)
  .sort();

const levels = ['manager', 'operator'];

const routesByLevel = Object.fromEntries(
  levels.map((level) => [
    level,
    routes.filter(
      (route) => route.meta?.protected && route.meta.acl.includes(level),
    )
      .map((route) => route.name)
      .sort(),
  ]),
);

export default {
  routesByLevel,
  unprotectedRoutes,
};
