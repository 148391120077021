export default {
  history: [],
  history_sources: [],
  history_users: [],
  filter: {
    page: 1,
    pages: 1,
    per_page: 50,
    charge_id: null,
    created_at: null,
    source: null,
    user_id: null,
    text: null,
  },
};
