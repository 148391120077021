const actionBlock = {
  icon: 'fa-ban',
  text: 'Remover da seleção',
  event: 'block_record',
  color: 'grey',
};
const actionUnblock = {
  icon: 'fa-check',
  text: 'Readicionar na seleção',
  event: 'unblock_record',
  color: 'green',
};

const GetterProductsWithActions = (state) => state.products.map((product) => ({
  id: product.id,
  name: product.name,
  status: product.status,
  actions: product.status === 'active' ? [actionBlock] : [actionUnblock],
}));

export default {
  GetterProductsWithActions,
};
