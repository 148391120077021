import axios from 'axios';
import interceptor from './interceptor';

const baseURL = process.env.VUE_APP_API_URL;
const http = axios.create({ baseURL });

http.interceptors.response.use(
  (response) => response,
  (error) => {
    interceptor(error);
    return Promise.reject(error);
  },
);

const setBearerToken = (token) => { http.defaults.headers.common.Authorization = `Bearer ${token}`; };
export { http, setBearerToken };
