import Vue from 'vue';
import dateHandler from '@/functions/dateHandler/dateHandler';

Vue.filter('status_active_inactive', (status) => {
  const statusIndicators = {
    active: "<span class='badge color-green-white'>Ativo</span>",
    inactive: "<span class='badge color-red-white'>Inativo</span>",
  };
  return statusIndicators[status] ?? '';
});

Vue.filter('datetime_brl', (sourceDate) => {
  if (!sourceDate) return null;
  return dateHandler.format(sourceDate, 'DD/MM/YYYY H:mm');
});

Vue.filter('platform_logo', (company) => {
  if (!company) return null;

  const companyNames = {
    braip: 'Braip',
    perfectpay: 'PerfectPay',
    eduzz: 'Eduzz',
    hotmart: 'Hotmart',
    upnid: 'Upnid',
  };

  const companyToResearch = company.replace('nd_', '');

  return companyNames[companyToResearch] ?? company;
});

const chargeTab = (slug) => {
  const chargeTabs = [
    { slug: 'paid_charges', name: 'Pagamentos aprovados' },
    { slug: 'recharge_funnel', name: 'Funil de recobrança' },
    { slug: 'already_recharged', name: 'Já cobradas' },
    { slug: 'all_pending_charges', name: 'Todas em aberto' },
    { slug: 'closed_charges', name: 'Cobranças encerradas' },
    { slug: 'not_recharged', name: 'Cobranças pendentes' },
    { slug: 'waiting_2_recharge', name: 'Aguardando recobrança' },
    { slug: 'waiting_3_recharge', name: '3. Recobrança' },
    { slug: 'refused_payment', name: 'Pagamentos recusados' },
    { slug: 'billet_cancelled', name: 'Pedidos cancelados' },
    { slug: 'upsell', name: 'Upsell' },
  ];
  return chargeTabs.find((tab) => tab.slug === slug)?.name || null;
};

Vue.filter('chargeTab', chargeTab);

const chargeStatus = (slug) => {
  const chargeTabs = [
    { slug: 'order_overdue', name: 'Cobrança expirada' },
    { slug: 'order_pending', name: 'Pagamento pendente' },
    { slug: 'credit_card_refused', name: 'Cartão recusado' },
    { slug: 'order_approved', name: 'Pago' },
    { slug: 'order_refunded', name: 'Reembolsado' },
  ];
  return chargeTabs.find((tab) => tab.slug === slug)?.name || null;
};

Vue.filter('chargeStatus', chargeStatus);

const chargePaymentMethod = (paymentMethod) => {
  const paymentMethods = {
    boleto: 'Boleto',
    cartao_de_credito: 'Cartão de crédito',
    pix: 'Pix',
  };

  return paymentMethods[paymentMethod] || 'Não identificado';
};

Vue.filter('chargePaymentMethod', chargePaymentMethod);
