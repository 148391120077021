import login from '../services';
import storage from '../storage';

const ActionSetUser = ({ commit }, payload) => {
  commit('SET_USER', payload);
};
const ActionSetToken = ({ commit }, payload) => {
  storage.setHeaderToken(payload);
  storage.setLocalToken(payload);
  commit('SET_TOKEN', payload);
};

const ActionCheckToken = ({ dispatch, state }) => {
  if (state.token) {
    return Promise.resolve(state.token);
  }
  const token = storage.getLocalToken();
  if (!token) return Promise.reject(new Error('Token inválido'));
  storage.setHeaderToken(token);
  return dispatch('ActionLoadSession');
};

const ActionLoadSession = async ({ dispatch }) => {
  try {
    // eslint-disable-next-line camelcase
    const { data: { access_token, user } } = await login.loadSession();
    dispatch('ActionSetUser', user);
    dispatch('ActionSetToken', access_token);
    return Promise.resolve();
  } catch (error) {
    dispatch('ActionSignOut');
    return Promise.reject();
  }
};

const ActionLogin = ({ dispatch }, payload) => login.attempt(payload.email, payload.password)
  .then((res) => {
    dispatch('ActionSetUser', res.data.user);
    dispatch('ActionSetToken', res.data.access_token);
  });

const ActionSignOut = ({ dispatch }) => {
  dispatch('ActionSetToken', '');
  dispatch('ActionSetUser', {});
};

export default {
  ActionSetUser,
  ActionSetToken,
  ActionCheckToken,
  ActionLoadSession,
  ActionLogin,
  ActionSignOut,
};
