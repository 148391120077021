const SET_LAST_WEEK_PAID_CHARGES = (state, payload) => {
  state.lastWeekPaidChargesPerDate = payload;
};

const SET_CHARGES_PER_PRODUCT = (state, payload) => {
  state.chargesPerProduct = payload;
};

const SET_NOT_PROCESSED_TOTAL = (state, payload) => {
  state.notProcessed.receivedPostsNotProcessed = payload;
};

const SET_NOT_PROCESSED_TODAY = (state, payload) => {
  state.notProcessed.receivedPostsNotProcessedToday = payload;
};

const SET_NOT_PROCESSED_PER_PLATFORM = (state, totalPerPlatform) => {
  state.notProcessed.receivedPostsNotProcessedPerPlatform = totalPerPlatform;
};

const SET_NOT_PROCESSED_PER_PLATFORM_TODAY = (state, totalPerPlatform) => {
  state.notProcessed.receivedPostsNotProcessedPerPlatformToday = totalPerPlatform;
};

export default {
  SET_LAST_WEEK_PAID_CHARGES,
  SET_CHARGES_PER_PRODUCT,
  SET_NOT_PROCESSED_TOTAL,
  SET_NOT_PROCESSED_TODAY,
  SET_NOT_PROCESSED_PER_PLATFORM,
  SET_NOT_PROCESSED_PER_PLATFORM_TODAY,
};
