export default [
  {
    icon: 'fa-dollar-sign',
    text: 'Cobranças',
    path: '/',
  },
  {
    icon: 'fa-cogs',
    text: 'Dashboard',
    path: '/dashboard',
    acl: ['manager'],
  },
  {
    icon: 'fa-comments-dollar',
    text: 'Mensagens',
    path: '/mensagens',
  },
  {
    icon: 'fa-inbox',
    text: 'Não processados',
    path: '/nao_processados',
    acl: ['manager'],
  },
  {
    icon: 'fa-history',
    text: 'Histórico',
    path: '/historico',
  },
  {
    icon: 'fa-box-open',
    text: 'Produtos',
    path: '/produtos',
    acl: ['manager'],
  },
  {
    icon: 'fa-user-tie',
    text: 'Administradores',
    path: '/administradores',
    acl: ['manager'],
  },
  {
    icon: 'fa-user',
    text: 'Operadores',
    path: '/operadores',
    acl: ['manager'],
  },
  {
    icon: 'fa-key',
    text: 'Minha senha',
    path: '/alteracaodesenha',
  },
];
