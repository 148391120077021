const ActionToggleShowMenuIndicator = ({ commit, state }) => {
  const newShowMenuIndicator = state.template_show_menu === 'yes'
    ? 'no'
    : 'yes';
  commit('SET_SHOW_MENU_INDICATOR', newShowMenuIndicator);
};

const ActionCheckWindowSize = async ({ commit }) => {
  await commit('SET_IS_MOBILE_INDICATOR', window.innerWidth < 601);
};

const ActionSetInitialShowMenuIndicator = async (context) => {
  if (!context.state.template_show_menu) {
    await context.dispatch('ActionCheckWindowSize');
    context.commit(
      'SET_SHOW_MENU_INDICATOR',
      context.state.is_mobile ? 'no' : 'yes',
    );
  }
};

export default {
  ActionToggleShowMenuIndicator,
  ActionCheckWindowSize,
  ActionSetInitialShowMenuIndicator,
};
