export default {
  SET_LIST_receivedPosts(state, payload) {
    state.receivedPosts = payload;
  },
  SET_receivedPosts_CURRENT_PAGE(state, page) {
    state.filter.page = page;
  },
  SET_receivedPosts_PAGES(state, pages) {
    state.filter.pages = pages;
  },
  SET_LIST_PLATFORMS(state, payload) {
    state.platforms = payload;
  },
  SET_PLATFORM_FILTER(state, platform) {
    state.filter.platform = platform;
  },
};
