import moment from 'moment';

const format = (date, formatToBeApplied) => {
  let dateToBeFormatted;
  if (typeof date === 'object') {
    dateToBeFormatted = { ...date };
  } else {
    dateToBeFormatted = new Date(date);
  }
  return moment(dateToBeFormatted).format(formatToBeApplied);
};

export default {
  format,
};
