export default [
  {
    path: '/historico',
    component: () => import(/* webchunkName: 'history' */ '@/pages/history/pages/History.vue'),
    name: 'history',
    meta: {
      protected: true,
      acl: ['manager', 'operator'],
      title: 'Histórico',
      layout: 'default_template',
    },
  },
];
