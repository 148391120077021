export default [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkname: 'login' */ './pages/login/index.vue'),
    meta: {
      title: 'Acesso ao sistema',
      protected: false,
      layout: 'fullscreen',
    },
  },
];
