export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkname: 'dashboard' */ './Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      protected: true,
      acl: ['manager'],
      layout: 'default_template',
    },
  },
];
