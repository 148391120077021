import { http } from '@/http';

const services = {
  listOrdersOrigins: {
    method: 'get',
    url: '/apicharges/orders_origins',
  },
};

const listOrdersOrigins = () => http({
  method: services.listOrdersOrigins.method,
  url: services.listOrdersOrigins.url,
});

export default {
  listOrdersOrigins,
};
