export default {
  SET_MESSAGES_LIST(state, payload) {
    state.messages = payload;
  },
  SET_MESSAGES_LIST_CURRENT_PAGE(state, payload) {
    state.messagesFilter.page = payload;
  },
  SET_MESSAGES_LIST_PAGES(state, payload) {
    state.messagesFilter.pages = payload;
  },
  SET_MESSAGES_LIST_FILTER(state, payload) {
    Object.keys(payload).forEach((key) => { state.messagesFilter[key] = payload[key]; });
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
  SET_MESSAGE_PROPERTY(state, { property, content }) {
    state.message[property] = content;
  },
  SET_MESSAGE_ERROR(state, messageErrors) {
    state.messageErrors = messageErrors;
  },
};
