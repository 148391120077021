import { http } from '@/http';
import functions from '@/functions';

const services = {
  listReceivedPosts: {
    url: '/apireceivedposts',
    method: 'get',
  },
  reprocessReceivedPost: {
    url: '/apireceivedposts/reprocess',
    method: 'post',
  },
  deleteReceivedPost: {
    url: '/apireceivedposts',
    method: 'delete',
  },
  retrievePlatforms: {
    url: '/apireceivedposts/platforms',
    method: 'get',
  },
};

const listReceivedPosts = (filter) => {
  const httpQuery = functions.serialize(filter);
  return http({
    method: services.listReceivedPosts.method,
    url: `${services.listReceivedPosts.url}?${httpQuery}`,
  });
};

const reprocessReceivedPost = (id) => http({
  method: services.reprocessReceivedPost.method,
  url: `${services.reprocessReceivedPost.url}/${id}`,
});

const deleteReceivedPost = (id) => http({
  method: services.deleteReceivedPost.method,
  url: `${services.deleteReceivedPost.url}/${id}`,
});

const retrievePlatforms = () => http({
  method: services.retrievePlatforms.method,
  url: services.retrievePlatforms.url,
});

export default {
  listReceivedPosts,
  reprocessReceivedPost,
  deleteReceivedPost,
  retrievePlatforms,
};
