import { http } from '@/http';
import functions from '@/functions';

const services = {
  listHistory: {
    method: 'get',
    url: '/apihistory',
  },
  listHistorySources: {
    method: 'get',
    url: '/apihistory/sources',
  },
  listHistoryUsers: {
    method: 'get',
    url: '/apihistory/users',
  },
};

const listHistory = (filter) => {
  const httpQuery = functions.serialize(filter);
  return http({
    method: services.listHistory.method,
    url: `${services.listHistory.url}?${httpQuery}`,
  });
};

const listHistorySources = () => http({
  method: services.listHistorySources.method,
  url: services.listHistorySources.url,
});

const listHistoryUsers = () => http({
  method: services.listHistoryUsers.method,
  url: services.listHistoryUsers.url,
});

export default {
  listHistory,
  listHistorySources,
  listHistoryUsers,
};
