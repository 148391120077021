import { http } from '@/http';

const removeNullParms = (parms) => {
  const parmsKeys = Object.keys(parms);
  const cleanedParm = {};
  parmsKeys.forEach((key) => {
    if (parms[key]) cleanedParm[key] = parms[key];
  });
  return cleanedParm;
};

const serialize = (parms) => {
  if (!parms) return '';
  return new URLSearchParams(removeNullParms(parms)).toString();
};

const cloneObject = (parm) => JSON.parse(JSON.stringify(parm));

const cleanObjectProperties = (parm) => {
  const cleanedParm = {};
  Object.keys(parm).forEach((key) => { cleanedParm[key] = ''; });
  return cleanedParm;
};

const copyTextboxToClipboard = (inputId) => {
  const component = document.getElementById(inputId);
  component.select();
  return document.execCommand('copy');
};

const createCrudServices = (url) => {
  const services = {};
  services.list = (filter) => http({
    url: `${url}?${serialize(filter)}`,
    method: 'get',
  });
  services.create = (data) => http({
    url: `${url}`,
    method: 'post',
    data,
  });
  services.read = (id) => http({
    url: `${url}/${id}`,
    method: 'get',
  });
  services.update = (id, data) => http({
    url: `${url}/${id}`,
    method: 'put',
    data,
  });
  services.destroy = (id) => http({
    url: `${url}/${id}`,
    method: 'delete',
  });
  return services;
};

export default {
  serialize,
  removeNullParms,
  cloneObject,
  cleanObjectProperties,
  copyTextboxToClipboard,
  createCrudServices,
};
