export default [
  {
    path: '/mensagens',
    name: 'billing_messages',
    component: () => import(/* webpackChunk: 'billingmessages' */ '@/pages/billingmessages/pages/BillingMessages'),
    meta: {
      protected: true,
      acl: ['manager', 'operator'],
      title: 'Mensagens de cobrança',
      layout: 'default_template',
    },
  },
];
