import auth from '@/modules/auth/store';
import passwordChange from '@/modules/password_change/store';
import template from '@/theme/template/store';
import managers from '@/pages/managers/store';
import operators from '@/pages/operators/store';
import billingMessages from '@/pages/billingmessages/store';
import productSelector from '@/components/Selectors/ProductSelector/store';
import operatorSelector from '@/components/Selectors/OperatorSelector/store';
import platformSelector from '@/components/Selectors/PlatformSelector/store';
import paymentMethodSelector from '@/components/Selectors/PaymentMethodSelector/store';
import ordersOriginsSelector from '@/components/Selectors/OrdersOriginsSelector/store';
import receivedPosts from '@/pages/receivedposts/store';
import charges from '@/pages/charges/store';
import products from '@/pages/products/store';
import histories from '@/pages/history/store';
import dashboard from '@/pages/dashboard/store';

export default {
  auth,
  passwordChange,
  template,
  managers,
  operators,
  billingMessages,
  productSelector,
  operatorSelector,
  platformSelector,
  receivedPosts,
  charges,
  products,
  histories,
  dashboard,
  ordersOriginsSelector,
  paymentMethodSelector,
};
