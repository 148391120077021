import store from '../store';
import routes from './routes';
import routesACL from './routesACL';

export default async (to, from, next) => {
  document.title = to.meta.title;
  const protectedRoutes = routes.filter((route) => route.meta.protected).map((route) => route.name);
  if (to.name !== 'login') {
    if (protectedRoutes.indexOf(to.name) !== -1) {
      try {
        await store.dispatch('auth/ActionCheckToken');
        const userLoggedLevel = store.state.auth.user?.level;
        if (
          userLoggedLevel
          && routesACL.routesByLevel[userLoggedLevel]
          && routesACL.routesByLevel[userLoggedLevel].includes(to.name)
        ) {
          next();
        } else {
          next('/');
        }
      } catch (error) {
        next({ name: 'login' });
      }
    } else {
      next();
    }
  } else {
    try {
      await store.dispatch('auth/ActionCheckToken');
      next({ name: 'standard_route' });
    } catch (error) {
      console.log(error);
      next();
    }
  }
};
