const standardDataActions = [
  {
    icon: 'fa-edit',
    text: 'Editar operador ',
    event: 'edit_record',
    color: 'orange',
  },
  {
    icon: 'fa-trash',
    text: 'Apagar operador',
    event: 'delete_record',
    color: 'red',
  },
];
const actionBlock = {
  icon: 'fa-ban',
  text: 'Bloquear acesso ',
  event: 'block_record',
  color: 'grey',
};
const actionUnblock = {
  icon: 'fa-check',
  text: 'Desbloquear acesso ',
  event: 'unblock_record',
  color: 'green',
};

const getUsersWithActions = (state) => state.users.map((user) => ({
  ...user,
  actions: [
    ...standardDataActions,
    user.status === 'active'
      ? actionBlock
      : actionUnblock,
  ],
}));

export default {
  getUsersWithActions,
};
