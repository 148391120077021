import { setBearerToken } from '@/http';

const setHeaderToken = (token) => setBearerToken(token);
const getLocalToken = () => localStorage.getItem('access_token');
const setLocalToken = (token) => localStorage.setItem('access_token', token);
const deleteLocalToken = () => localStorage.deleteItem('access_token');

export default {
  setHeaderToken,
  getLocalToken,
  setLocalToken,
  deleteLocalToken,
};
