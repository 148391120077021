import Swal from 'sweetalert2';
import Vue from 'vue';

const standardMessage = async (icon, parms) => {
  const swalParms = {
    icon,
  };
  if (typeof parms === 'object') {
    if (parms.title) swalParms.title = parms.title;
    if (parms.text) swalParms.text = parms.text;
  } else {
    swalParms.text = parms;
  }
  await Swal.fire(swalParms);
  return Promise.resolve();
};

const success = (parms) => standardMessage('success', parms);
const warning = (parms) => standardMessage('warning', parms);
const error = (parms) => standardMessage('error', parms);

const toast = async (text) => {
  await Swal.fire({
    text,
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    icon: 'success',
  });
  return Promise.resolve();
};

const confirm = async (parms) => {
  let swalParms = {
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'OK',
    cancelButtonText: 'Cancelar',
  };

  if (typeof parms === 'object') {
    swalParms = { ...swalParms, ...parms };
  } else {
    swalParms.title = parms;
  }

  try {
    const result = await Swal.fire(swalParms);
    return result.isConfirmed ? Promise.resolve() : Promise.reject();
  } catch (swalError) {
    return Promise.reject(swalError);
  }
};

const dialogbox = {
  confirm,
  success,
  warning,
  error,
  toast,
};

Vue.prototype.$dialogbox = dialogbox;

export default {
  ...dialogbox,
};
