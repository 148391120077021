import { http } from '@/http';
import functions from '@/functions';

const services = {
  listCharges: {
    url: '/apicharges',
    method: 'get',
  },
  getCharge: {
    url: '/apicharges',
    method: 'get',
  },
  getChargesBoardStats: {
    url: '/apidashboard/charges_board_stats',
    method: 'get',
  },
  registerContact: {
    url: '/apicontacts/register_contact',
    method: 'post',
  },
  closeCharge: {
    url: '/apicharges/close_charge',
    method: 'post',
  },
};

const listCharges = (filter) => {
  const httpQuery = functions.serialize(filter);
  return http({
    method: services.listCharges.method,
    url: `${services.listCharges.url}?${httpQuery}`,
  });
};

const getChargesBoardStats = (filter) => {
  const httpQuery = functions.serialize(filter);
  return http({
    method: services.getChargesBoardStats.method,
    url: `${services.getChargesBoardStats.url}?${httpQuery}`,
  });
};

const getCharge = (id) => http({
  method: services.getCharge.method,
  url: `${services.getCharge.url}/${id}`,
});

const registerContact = (data) => http({
  method: services.registerContact.method,
  url: `${services.registerContact.url}`,
  data,
});

const closeCharge = (id) => http({
  method: services.closeCharge.method,
  url: `${services.closeCharge.url}/${id}`,
});

export default {
  listCharges,
  getCharge,
  getChargesBoardStats,
  registerContact,
  closeCharge,
};
