import services from '../services';

const ActionlistHistory = async ({ commit, state }) => {
  const { filter } = state;

  try {
    const response = await services.listHistory(filter);
    commit('SET_HISTORY_LIST', response.data.data);
    commit('SET_PAGES', response.data.last_page);
    commit('SET_CURRENT_PAGE', response.data.current_page);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

const ActionListHistoryFilter = ({ commit, dispatch }, filter) => {
  commit('SET_FILTER', filter);
  commit('SET_CURRENT_PAGE', 1);
  return dispatch('ActionlistHistory');
};

const ActionListHistoryPaginate = ({ commit, dispatch }, page) => {
  commit('SET_CURRENT_PAGE', page);
  return dispatch('ActionlistHistory');
};

const ActionListHistorySources = async ({ commit }) => {
  try {
    const sources = await services.listHistorySources();
    commit('SET_LIST_HISTORY_SOURCES', sources.data);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

const ActionListHistoryUsers = async ({ commit }) => {
  try {
    const users = await services.listHistoryUsers();
    commit('SET_LIST_HISTORY_USERS', users.data);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  ActionlistHistory,
  ActionListHistoryFilter,
  ActionListHistoryPaginate,
  ActionListHistorySources,
  ActionListHistoryUsers,
};
