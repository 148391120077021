export default {
  receivedPosts: [],
  filter: {
    page: 1,
    per_page: 20,
    pages: 1,
    status: 'not_processed',
  },
  platforms: [],
};
