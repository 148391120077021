export default [
  {
    path: '/cobrancas/:id/detalhes',
    name: 'charge_details',
    component: () => import(/* webpackChunk: 'receivedPosts' */ '@/pages/charges/pages/ChargeDetails'),
    meta: {
      title: 'Detalhes da cobrança',
      protected: true,
      acl: ['manager', 'operator'],
      layout: 'default_template',
    },
  },
  {
    path: '/cobrancas',
    name: 'charges',
    component: () => import(/* webpackChunk: 'receivedPosts' */ '@/pages/charges/pages/Charges'),
    meta: {
      title: 'Cobranças',
      protected: true,
      acl: ['manager', 'operator'],
      layout: 'default_template',
    },
  },
];
