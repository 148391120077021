const SET_SHOW_MENU_INDICATOR = (state, payload) => {
  state.template_show_menu = payload;
};

const SET_IS_MOBILE_INDICATOR = (state, payload) => {
  state.is_mobile = payload;
};

export default {
  SET_SHOW_MENU_INDICATOR,
  SET_IS_MOBILE_INDICATOR,
};
