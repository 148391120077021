import { http } from '@/http';
import functions from '@/functions';

const services = {
  listUsers: { method: 'get', url: '/apioperators' },
  insertUser: { method: 'post', url: '/apioperators' },
  getUser: { method: 'get', url: '/apioperators/{id}' },
  updateUser: { method: 'put', url: '/apioperators/{id}' },
  deleteUser: { method: 'delete', url: '/apioperators/{id}' },
  toggleBlockUser: { method: 'put', url: '/apioperators/{id}/toggle_block' },
};

const listUsers = (filter) => {
  const httpQuery = functions.serialize(filter);
  return http({
    method: services.listUsers.method,
    url: `${services.listUsers.url}?${httpQuery}`,
  });
};

const insertUser = (payload) => http({
  method: services.insertUser.method,
  url: services.insertUser.url,
  data: payload,
});

const getUser = (id) => http({
  method: services.getUser.method,
  url: services.getUser.url.replace('{id}', id),
});

const updateUser = (id, data) => http({
  method: services.updateUser.method,
  url: services.updateUser.url.replace('{id}', id),
  data,
});

const deleteUser = (id) => http({
  method: services.deleteUser.method,
  url: services.deleteUser.url.replace('{id}', id),
});

const toggleBlockUser = (id) => http({
  method: services.toggleBlockUser.method,
  url: services.toggleBlockUser.url.replace('{id}', id),
});

export default {
  listUsers,
  insertUser,
  getUser,
  updateUser,
  deleteUser,
  toggleBlockUser,
};
