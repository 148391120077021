import { http } from '@/http';
import functions from '@/functions';

const services = {
  listProducts: {
    method: 'get',
    url: '/apiproducts',
  },
  toggleStatus: {
    method: 'put',
    url: '/apiproducts',
  },
};

const listProducts = (filter) => http({
  method: services.listProducts.method,
  url: `${services.listProducts.url}?${functions.serialize(filter)}`,
});

const toggleStatus = (productId) => http({
  method: services.toggleStatus.method,
  url: `${services.toggleStatus.url}/${productId}/toggle_status`,
});

export default {
  listProducts,
  toggleStatus,
};
