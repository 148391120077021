import { http } from '@/http';

const services = {
  listOperators: {
    method: 'get',
    url: '/apioperators?per_page=1000',
  },
};

const listOperators = () => http({
  method: services.listOperators.method,
  url: services.listOperators.url,
});

export default {
  listOperators,
};
