import services from '../services';

const actionListUsers = async ({ commit, state }) => {
  const filter = state.usersFilter;
  const res = await services.listUsers(filter);
  const { data: users } = res.data;
  commit('SET_USER_LIST_CURRENT_PAGE', res.data.current_page);
  commit('SET_USER_LIST_PAGES', res.data.last_page);
  commit('SET_USER_LIST', users);
};

const actionListUsersPaginate = async ({ dispatch, commit }, payload) => {
  commit('SET_USER_LIST_CURRENT_PAGE', payload.page);
  await dispatch('actionListUsers');
};

const actionListUsersApplyFilter = async ({ dispatch, commit }, payload) => {
  commit('SET_USER_LIST_FILTER', payload.filter);
  commit('SET_USER_LIST_CURRENT_PAGE', 1);
  await dispatch('actionListUsers');
};

const ActionInsertUser = async (context, payload) => {
  try {
    const userInserted = (await services.insertUser(payload)).data;
    return Promise.resolve(userInserted);
  } catch (error) {
    return Promise.reject(error);
  }
};

const ActionGetUser = async (context, { id }) => {
  try {
    const user = (await services.getUser(id)).data;
    return Promise.resolve(user);
  } catch (error) {
    return Promise.reject(error);
  }
};

const ActionUpdateUser = async (context, { id, data }) => {
  try {
    const user = (await services.updateUser(id, data)).data;
    return Promise.resolve(user);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const ActionDeleteUser = (context, { id }) => services.deleteUser(id);

export const ActionToggleBlockUser = (context, { id }) => services.toggleBlockUser(id);

export default {
  actionListUsers,
  actionListUsersPaginate,
  actionListUsersApplyFilter,
  ActionInsertUser,
  ActionGetUser,
  ActionUpdateUser,
  ActionDeleteUser,
  ActionToggleBlockUser,
};
