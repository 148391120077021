export default [
  {
    path: '/operadores',
    name: 'operators_list',
    component: () => import(/* webpackChunk: 'operators_list' */ '@/pages/operators/Users.vue'),
    meta: {
      title: 'Operadores',
      protected: true,
      acl: ['manager'],
      layout: 'default_template',
    },
  },
];
