export default [
  {
    path: '/produtos',
    component: () => import(/* webchunkName: 'products' */ '@/pages/products/pages/Products.vue'),
    name: 'products',
    meta: {
      protected: true,
      acl: ['manager'],
      title: 'Produtos',
      layout: 'default_template',
    },
  },
];
