const defaultTabSlug = 'not_recharged';
export default {
  charges: [],
  charge: {},
  activeTab: defaultTabSlug,
  filter: {
    page: 1,
    per_page: 20,
    pages: 1,
    active_tab: defaultTabSlug,
    product_id: 0,
    operator_id: 0,
    platform: null,
    payment_method: null,
    global_search: null,
    received_at_from: null,
    received_at_until: null,
    customer_cpf: null,
    order_origin: null,
  },
  chargesBoardStats: {
    paid_charges: 0,
    recharge_funnel: 0,
    already_recharged: 0,
    all_pending_charges: 0,
    closed_charges: 0,
    waiting_2_recharge: 0,
    waiting_3_recharge: 0,
    refused_payment: 0,
    upsell: 0,
    not_recharged: 0,
  },
  chargeTabs: [
    { slug: 'paid_charges', name: 'Pagamentos aprovados' },
    { slug: 'recharge_funnel', name: 'Funil de recobrança' },
    { slug: 'already_recharged', name: 'Já cobradas' },
    { slug: 'all_pending_charges', name: 'Todas em aberto' },
    { slug: 'closed_charges', name: 'Cobranças encerradas' },
    { slug: 'not_recharged', name: 'Cobranças pendentes' },
    { slug: 'waiting_2_recharge', name: 'Aguardando recobrança' },
    { slug: 'waiting_3_recharge', name: '3. Recobrança' },
    { slug: 'refused_payment', name: 'Pagamentos recusados' },
    { slug: 'billet_cancelled', name: 'Pedidos cancelados' },
    { slug: 'upsell', name: 'Upsell' },
  ],
  ordersOrigins: [],
};
