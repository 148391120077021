export default {
  products: [
    { id: 1, name: 'Produto 1', status: 'active' },
    { id: 2, name: 'Produto 1', status: 'active' },
    { id: 3, name: 'Produto 1', status: 'inactive' },
    { id: 4, name: 'Produto 1', status: 'active' },
  ],
  filter: {
    status: null,
  },
};
