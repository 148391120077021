export default [
  {
    path: '/',
    name: 'standard_screen_after_login',
    component: () => import(/* webpackChunk: 'receivedPosts' */ '@/pages/charges/pages/Charges'),
    meta: {
      title: 'Cobranças',
      protected: true,
      acl: ['manager', 'operator'],
      layout: 'default_template',
    },
  },
  {
    path: '*',
    name: 'standard_route',
    component: () => import('@/pages/charges/pages/Charges.vue'),
    meta: {
      title: 'Buraco negro',
      protected: true,
      acl: ['manager', 'operator'],
    },
  },
];
