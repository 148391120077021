export default {
  SET_HISTORY_LIST(state, payload) {
    state.history = payload;
  },
  SET_CURRENT_PAGE(state, page) {
    state.filter.page = page;
  },
  SET_PAGES(state, pages) {
    state.filter.pages = pages;
  },
  SET_FILTER(state, filter) {
    Object.keys(filter).forEach((key) => { state.filter[key] = filter[key]; });
  },
  SET_LINES_PER_PAGE(state, perPage) {
    state.filter.per_page = perPage;
  },
  SET_LIST_HISTORY_SOURCES(state, payload) {
    state.history_sources = payload;
  },
  SET_LIST_HISTORY_USERS(state, payload) {
    state.history_users = payload;
  },
};
